import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AppBar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import '../assets/Layout.css';

const tabs = [
    { label: "Home", route: "/", value: 0 },
    { label: "About Us", route: "/about-us", value: 1 },
    { label: "Registration", route: "/registration", value: 2 },
    { label: "Gear", route: "/gear", value: 3 }
];

function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

export const Layout = () => {
    const [tabValue, setTabValue] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const tab = tabs.find((tab) => tab.route === location.pathname);
        setTabValue(tab ? tab.value : 0);
        console.log('Location changed', location);
    }, [location]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
            samePageLinkNavigation(
                event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            ))
        ) {
            setTabValue(newValue);
        }
    };

    
    return (
        <AppBar position="static">
            <div className='title-row'>
                Silicon Valley RFC
            </div>
            <Tabs 
                value={tabValue}
                className='navigation-row'
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="standard"
                aria-label="Nav Tabs"
            >
                {tabs.map((tab) => <Tab component={Link} label={tab.label} to={tab.route}/>)}
                {/* 
                <Tab component={Link} label="Calendar" to="/calendar"/>
                <Tab component={Link} label="Events" to="/events"/>
                <Tab component={Link} label="Assets" to="/assets"/>
                <Tab component={Link} label="Teams" to="/teams"/>
                <Tab component={Link} label="Dibs???" to="/dibs"/> */}
            </Tabs>
        </AppBar>
    );
}