import { TitleBar } from "../components/TitleBar";
import '../assets/Gear.css';

export const GearPage = () => {
    return (
        <>
            <TitleBar title='Join SV Gear Orders!'/>
            <div className='gear-wrapper'>
                <p>Silicon Valley Rugby is now collecting statements of interest and funds for a few Silicon Valley Rugby logo items:</p>
                <ul>
                    <li>The Silicon Valley Rugby barrel bag (comes in three sizes).</li>
                    <li>A Silicon Valley Rugby logo boot bag.</li>
                    <ul>
                        <li>A personalized name tag, optional.</li>
                    </ul>
                    <li>A Silicon Valley Rugby logo red-and-black hooped cotton jersey.</li>
                </ul>
                <p>
                    All of these are custom orders from the vendor. We'll collect names and contact info for each
                    item you'd like to purchase in the Google Forms below this month, collect funds from
                    everyone interested in any of the items, and place a consolidated order with the vendor at the
                    end of March. Customizing the items and shipping will take six to eight (6 - 8) weeks to get
                    to Silicon Valley. Then we'll work on distributing the bags, tags, &amp; jerseys at training or by
                    appointment.
                </p>
                <p>
                    The kit bags are made in Seattle. The jerseys are made in Kitchener, Ontario (a ways outside
                    Toronto, Canada). They are investment quality, and they cost an investment price.
                    10% of your price rebates to Silicon Valley Rugby.
                </p>
                <div className='title'>Custom SV Logo Kit Bag</div>
                <p>
                    You've seen many of the Silicon Valley Rugby kit bags on the side of the pitch. Now's your
                    chance to get your own, customized with your name (or whatever you'd like). These bags last
                    decades, so you can show off your SV pride for years.
                    Sign up to order a custom kit bag: 
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9r2uzyIsMZNnRNTh71ZUIhbxdlzNZqBIxMsHhz30uYyY5Og/viewform">Kit Form</a>
                </p>
                <div className="image-wrapper">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9r2uzyIsMZNnRNTh71ZUIhbxdlzNZqBIxMsHhz30uYyY5Og/viewform">
                        <img src="/images/kit-bag.png" alt="kit bag" width="500"/>
                    </a>
                </div>
                <div className='title'>SV Logo Boot Bag</div>
                <p>
                    When your boots are muddy, wet, or just ripe after the end of a match or training, the last
                    place you want to stow those boots is your kit bag along with all your other clothes and
                    effects. The boot bag gives you a dedicated space for your boots when they're not at their
                    best. Also comes with a side pocket for a water bottle, and a zippered pocket for keys,
                    mouthguard case, or similar items. Or maybe you want an overnight bag for your toiletries,
                    meds, and charge cords, customized with the SV logo.
                </p>
                <p>
                    The boot bag isn't customizable with your name (we asked). So maybe a custom name tag
                    will help keep your boot bag separate from all the others on the side of the pitch, without
                    having to open it up to check out what's inside. Sign up to order a Silicon Valley Rugby logo
                    boot bag, a personalized name tag, or both: 
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdYRljKqhUP5tIvjGUVRog4qTSW_QcMJEbObFMbS959ZGJoDg/viewform?usp=dialog">Boot Bag Form</a>
                </p>
                <div className="image-wrapper">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdYRljKqhUP5tIvjGUVRog4qTSW_QcMJEbObFMbS959ZGJoDg/viewform?usp=dialog">
                        <img src="/images/boot-bag.png" alt="boot bag" width="500"/>
                    </a>
                </div>
                <div className='title'>SV Logo Hooped Cotton Jersey</div>
                <p>
                    These 100% cotton jerseys are what a lot of people think of when they say "rugby jersey".
                    It might be the kind of thing your father or uncle would have worn on the pitch.
                    Made in Canada by Barbarian, these are as old-school as old-school gets.
                    Sign up to order a Silicon Valley Rugby logo old-school hooped jersey: 
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdILdmVLxUitV2a0hjtIpRd3yDM-X8FY7P3nBMrslJR2hXo2w/viewform?usp=dialog">Jersey Form</a>
                </p>
                <div className="image-wrapper">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdILdmVLxUitV2a0hjtIpRd3yDM-X8FY7P3nBMrslJR2hXo2w/viewform?usp=dialog">
                        <img src="/images/jerseys.png" alt="jerseys" width="500"/>
                    </a>
                </div>
                <div className='title'>Silicon Valley Rugby Pro Shop by World Rugby Shop</div>
                <p>
                    As always, if you're not interested in the kit bag, the boot bag, or the cotton jersey, but you
                    are up for a Silicon Valley Rugby logo t-shirt, polo, jacket, hoodie, ball cap, beanie, bucket
                    hat, training singlet, contact training top, pair of gym shorts, pair of track pants, track jacket,
                    quarter-zip training top, gilet, or match shorts, our partners at World Rugby Shop support us
                    with these items and more, mostly from Canterbury Clothing Company (CCC). These items,
                    unlike the kit bag, boot bag, and cotton jersey, will be paid to World Rugby Shop in their
                    checkout, then World Rugby Shop will screen or embroider the items you order, and ship
                    them directly to you. Items should arrive within a month. And again, WRS rebates 10% of the
                    purchase price to Silicon Valley Rugby.
                </p>
                <p>
                    Shop now for the items below, and many others: 
                    <a href="https://www.worldrugbyshop.com/collections/silicon-valley-high-school-mens-rugby-club">World Rugby Shop</a>
                </p>
                <div className="image-wrapper">
                    <a href="https://www.worldrugbyshop.com/collections/silicon-valley-high-school-mens-rugby-club">
                        <img src="/images/world-rugby-shop.png" alt="world rugby shop" width="500"/>
                    </a>
                </div>
            </div>
        </>
    );
}